<template>
  <LayoutPopupSkeleton class="warning-popup">
    <div class="warning-popup__title">
      <span class="warning-popup__font warning-popup__font--title">
        {{ title }}
      </span>
    </div>

    <button class="warning-popup__button" @click="closePopup">
      <span class="warning-popup__font warning-popup__font--button">
        {{ state.app.locale.redirectPage.understandable }}
      </span>
    </button>
  </LayoutPopupSkeleton>
</template>

<script>
import LayoutPopupSkeleton from "@/components/layout/PopupSkeleton.vue";
import { useState } from "@/App.store";

export default {
  name: "WarningPopup",
  components: { LayoutPopupSkeleton },
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    const state = useState();
    return {
      state,
    };
  },
  methods: {
    closePopup() {
      this.$popup.close();
    },
  },
};
</script>

<style scoped lang="scss">
.warning-popup {
  &__button {
    margin: 24px auto 0;
    background-color: transparent;
    color: $color-dark;
    text-decoration: underline;
  }

  &__font {
    &--title {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 21px;
      font-weight: 700;
      line-height: 28px;
    }

    &--button {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 14px;
    }
  }
}
</style>
